.Management{
  
   margin: 30px 60px;
}
*{
    --bs-accordion-btn-padding-y: 0.7rem;
}



.Management-container{
   
display: flex;
justify-content: space-around;

width: 20%;
}

.Management-accordion{
    display: flex;
    justify-content: center;
}

.accordion-container{
    display: flex;
    justify-content: space-between;
}
.political{
    display: flex;
    justify-content: space-between;
    margin: 5px;
}
hr{
 margin: 0 !important;
}