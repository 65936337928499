* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Poppins-Regular";
  color: #333;
  font-size: 13px;
  margin: 0;
}

input,
textarea,
select,
button {
  font-family: "Poppins-Regular";
  color: #333;
  font-size: 13px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

img {
  max-width: 100%;
}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

a:hover {
  text-decoration: none;
}

:focus {
  outline: none;
}

.wrapper {
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(252, 224, 173);
}

.inner {
  background: #fff;
  width: 90%;

  display: flex;
}
.inner .image-holder {
  width: 50%;
}
.image-holder {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .wrapper {
    background-position: right center;
  }
}
@media (max-width: 991px) {
  .inner form {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .inner {
    display: block;
  }
  .inner .image-holder {
    width: 100%;
  }
  .inner form {
    width: 100%;
    padding: 40px 0 30px;
  }

  button {
    margin-top: 60px;
  }
}

/*# sourceMappingURL=style.css.map */
