
  
 
  
  .legal-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 95%;
    height: 400px;
    background: #f1f1f1;
    margin: 100px auto 0;
    word-break: break-all;
    border: 1px solid rgba(0, 0, 0, 0.274);
    font-size: 12px;
  }
  
  .bloc-tabs {
    display: flex;
  }
  .tabs {
    text-align: center;
    width: 30%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    background: white;
    border-bottom: 1px solid transparent;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: rgb(248, 177, 23);
  }
  
  
  
  button {
    border: none;
    
  }
  .content-tabs {
    flex-grow : 1;
  }
  .content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 334px;
    display: none;
    overflow-y: scroll;
  }
  .content h2 {
    padding: 0px 0 5px 0px;
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  /* .content p {
    width: 100%;
    height: 100%;
  } */
  .active-content {
    display: block;
  }

.oxfam-total{
  width: 100%;
  display: flex;
  justify-content: space-between;
 
}

.oxfam-tot-sub{
  width: 50%;
 
}