.topproject{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
.topproject > h1{
   color: orange;
   font-weight: 900;
}
.topproject-card{
   flex-direction: row !important;
   margin-top: 20px;
}
