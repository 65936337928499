@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("../../Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: bold;
}

/* * {
  font-family: Roboto;
} */

.main-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: 13px;
}
.ranking-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin-left: 20px;
}
.ranking-subcontainer {
  width: 70%;
  margin: 10px;
}
.about {
  width: 50%;
  margin-right: 20px;
  overflow-wrap: break-word;
  box-shadow: 0 0 5px 2px rgb(253, 227, 201);
  border-radius: 15px;
}
.details {
  font-size: 13px;
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.charts {
  display: flex;
  justify-content: space-around;
}
.Governance {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.governance-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.charts-last {
  width: 30%;
}
.box {
  height: 20px;
  width: 20px;
  border-radius: 5px;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}
.governance-subdiv {
  display: flex;
  justify-content: space-between;
  width: 15%;
}

svg > g[class^="raphael-group-"] g {
  fill: none !important;
}

.ngo-card {
  width: 15%;
  box-shadow: 0 0 5px 2px rgb(253, 227, 201);
  border: none !important;
  /* background-color:rgb(247, 245, 243) !important; */
}
.ngo-card :hover {
  cursor: pointer;
  background-color: rgb(253, 227, 201);
}
.sync-container {
  display: flex;
}
.sync-date {
  display: flex;
  flex-direction: column;
  width: 41%;
}
.tooltip > div.tooltip-inner {
  background-color: grey !important;
  color: white !important;
}
.tooltip.show {
  opacity: 1 !important;
}
.tooltip > div.arrow::before {
  border-bottom-color: rgb(255, 167, 3) !important;
  color: white !important;
}

@media screen and (max-width: 700px) {
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ranking-container {
    width: 98%;
  }
  .about {
    width: 98%;
    margin: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .details {
    display: flex;
    flex-direction: column;
  }
  .text-center {
    width: 95% !important;
    margin: 10px;
  }
  .charts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .charts-last {
    width: 95%;
    margin: 10px;
  }
}
