.firstpage-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.firstpage-button {
  margin-top: 50px;
  width: 60%;
  display: flex;
  justify-content: space-around;
}
.btn-reg {
  color: #ff6424 !important;
  border: 1px solid #ff6424 !important;
}

.btn-reg:hover {
  background-color: #ff6424 !important;
  color: white !important;
}
