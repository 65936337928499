@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap");
/* @import "../../Roboto/Roboto-Black.ttf"; */
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("../../Roboto/Roboto-Black.ttf") format("truetype");
  font-weight: bold;
}

/* * {
  font-family: "Roboto";
} */
.wrapper {
  background: rgb(255, 214, 137);
  padding: 0 20px 0 20px;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}
.side-image {
  background-image: url("https://media.istockphoto.com/id/1281150061/vector/register-account-submit-access-login-password-username-internet-online-website-concept.jpg?s=612x612&w=0&k=20&c=9HWSuA9IaU4o-CK6fALBS5eaO1ubnsM08EOYwgbwGBo=");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px 0 0 10px;
}
.img-form {
  width: 35px;
  position: absolute;
  top: 30px;
  left: 30px;
}
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text p {
  color: #fff;
  font-size: 18px;
}
i {
  font-weight: 400;
  font-size: 14px;
}
.right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.input-box {
  width: 60%;
  box-sizing: border-box;
}
.input-box header {
  font-weight: 700;
  text-align: center;
  margin-bottom: 45px;
}
.input-field {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 10px 0 10px;
}
.input {
  height: 45px;
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  margin-bottom: 20px;
  color: #40414a;
}
.input-box .input-field label {
  position: absolute;
  top: 10px;
  left: 10px;
  pointer-events: none;
  transition: 0.5s;
}
.input-field .input:focus ~ label {
  top: -10px;
  font-size: 13px;
}
.input-field .input:valid ~ label {
  top: -10px;
  font-size: 13px;
  color: #5d5076;
}
.input-field .input:focus,
.input-field .input:valid {
  border-bottom: 1px solid rgb(255, 214, 137);
}
.submit {
  border: none;
  outline: none;
  height: 45px;
  background: rgb(250, 216, 154);
  border-radius: 5px;
  transition: 0.4s;
}
.submit:hover {
  background: rgb(253, 166, 5);
  color: #000;
}
.signin {
  text-align: center;
  font-size: small;
  margin-top: 25px;
}
span a {
  text-decoration: none;
  font-weight: 700;
  color: #000;
  transition: 0.5s;
}
span a:hover {
  text-decoration: underline;
  color: #000;
}
@media only screen and (max-width: 768px) {
  .side-image {
    border-radius: 10px 10px 0 0;
  }
  img {
    width: 35px;
    position: absolute;
    top: 20px;
    left: 47%;
  }
  .text {
    position: absolute;
    top: 70%;
    text-align: center;
  }
  .text p,
  i {
    font-size: 17px;
  }
  .row {
    max-width: 420px;
    width: 100%;
  }
}
