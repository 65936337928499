.home-container {
  background-color: white;
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.home-subcontainer {
  background-color: white;
  box-shadow: 1px 1px 1px 1px gainsboro;
  width: 50%;
  border-radius: 5px;
  margin-top: 20px;
}
.input-container {
  display: flex;
}
.modal-button {
  cursor: pointer;
  font-size: 11px;
  margin: 5px 0;
  color: orange;
  margin-left: 23px;
  margin-right: 7px;
  width: 118px;
  margin-top: 12px;
}

.search-Home {
  width: 85%;

  box-shadow: 1px 1px 1px 1px rgb(250, 221, 167);
  /* border-bottom: 2px solid rgb(97, 196, 226); */
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 7px;
}
.search-Home > input {
  width: 97%;
  border: 0px none;
  margin-left: 9px;
  margin-right: -23px;
  margin-top: 10px;
  margin-bottom: 10px;
}
textarea:focus,
input:focus {
  outline: none;
}
.modal-container {
  background-color: aqua;
}
.dropdown {
  margin-left: 10px;
  max-height: 100px;
  overflow-y: scroll;
}
.dropdown-list {
  margin-bottom: 3px;
  color: black;
}
.nav-pills .nav-link.active {
  background-color: #fd720d !important;
}
.logout-home {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
.logout-home > button {
  background-color: #fd720d;
  color: white;
  width: 5%;
  height: 40px;
  border-radius: 5px;
}
