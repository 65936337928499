.present-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}
.state-container {
  width: 49%;
  height: 650px;
}
.state-subcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.state {
  box-shadow: 0 0 5px 2px rgb(248, 172, 9);
  width: 20%;
  height: 50px;
  margin: 20px;
  text-align: center;
  font-size: large;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
}
.state:hover {
  box-shadow: 0;
  background-color: rgb(255, 223, 155);
}
